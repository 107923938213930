import React, { useEffect, useState } from "react";
import axios from "axios";
import LightStatic1 from "../../assets/light/static/static_1.svg";
import LightStatic2 from "../../assets/light/static/static_2.svg";
import DarkStatic1 from "../../assets/dark/static/static_1.svg";
import DarkStatic2 from "../../assets/dark/static/static_2.svg";
import Static3 from "../../assets/light/static/static_3.svg";
import { Box, Grid } from "@mui/material";
import "./styles.scss";
import SignInForm from "../../components/signIn";
import SignUpForm from "../../components/signUp";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthToken } from "../../redux/Reducer/userReducer";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ForgotPassword from "../../components/forgotPassword";

interface IProps {
  page: string;
}

const LoginForm: React.FC<IProps> = ({ page }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);
  const [loading, setLoading] = useState(false);
  const methods = useForm();

  const handleLogin = async (data: any, fromSignup: boolean = false) => {
    setLoading(true);
    const options = {
      method: "POST",
      url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
      headers: { "content-type": "application/json" },
      data: {
        grant_type: "password",
        username: data?.email,
        password: data?.password,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "openid profile email",
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      },
    };
    let response;
    try {
      response = await axios(options);
      toast.success("Successfully logged in");
      dispatch(
        setAuthToken({
          token: response?.data?.id_token,
          source: fromSignup ? "signup" : "login",
        })
      );
      setLoading(false);
      return;
    } catch (error: any) {
      toast.error(
        error?.response?.data?.error_description ||
          error?.message ||
          "Signin failed"
      );
      console.error("Error logging in", error);
      setLoading(false);
    }
  };

  const handleSignup = async (data: any) => {
    setLoading(true);
    const options = {
      method: "POST",
      url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/signup`,
      headers: { "content-type": "application/json" },
      data: {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email: data?.email,
        password: data?.password,
        connection: process.env.REACT_APP_AUTH0_DB,
        user_metadata: {
          userName: data?.name,
          nickname: data?.email?.split("@")[0],
        },
      },
    };

    try {
      await axios(options);
      await handleLogin(data, true);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.error_description ||
          error?.response?.data?.message ||
          "Signup failed"
      );
      console.error("Error", error);
      setLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    const redirectUri = window.location.origin + "/dashboard";
    const authUrl =
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?` +
      `client_id=${encodeURIComponent(
        process.env.REACT_APP_AUTH0_CLIENT_ID as string
      )}` +
      `&audience=${encodeURIComponent(
        process.env.REACT_APP_AUTH0_AUDIENCE as string
      )}` +
      `&scope=openid profile email` +
      `&response_type=code` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&connection=google-oauth2`;

    window.location.href = authUrl;
  };

  const handleForgotPassword = async (data: any) => {
    setLoading(true);
    await new Promise((res) => setTimeout(res, 1000));
    toast.success("Reset mail sent successfully");
    setLoading(false);
    navigate("/sign-in");
  };

  const onSubmit = (data: any) => {
    if (location.pathname === "/sign-up") {
      handleSignup(data);
    } else if (location.pathname === "/sign-in") {
      handleLogin(data);
    } else if (location.pathname === "/forgot-password") {
      handleForgotPassword(data);
    }
  };

  const PageDecider = () => {
    switch (page) {
      case "signIn":
        return (
          <SignInForm handleGoogleLogin={handleGoogleLogin} loading={loading} />
        );
      case "signUp":
        return (
          <SignUpForm handleGoogleLogin={handleGoogleLogin} loading={loading} />
        );
      case "forgotPassword":
        return <ForgotPassword loading={loading} />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    methods.reset();
  }, [location.pathname, methods]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((data) => !loading && onSubmit(data))}
      >
        <Grid className="login-container">
          <Box position="absolute" bottom={0} right={0}>
            <img src={Static3} alt="" />
          </Box>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
              minHeight="70vh"
            >
              {/* <Box width="70%"> */}
              <PageDecider />
              {/* <button onClick={handleGoogleLogin}>Log in with Google</button>
            <form onSubmit={handleLogin}>
              <div>
                <label>Email:</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Password:</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit">Login</button>
            </form> */}
              {/* </Box> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: "flex" }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="baseline"
              height="fit-content"
              gap="20px"
            >
              <img src={isDarkMode ? DarkStatic1 : LightStatic1} alt="" />
              <img src={isDarkMode ? DarkStatic2 : LightStatic2} alt="" />
            </Box>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default LoginForm;

import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';

interface Column {
    id: string;
    label: string;
    minWidth?: number;
}

interface Row {
    [key: string]: any;
}

interface AppTableProps {
    columns: Column[];
    rows: Row[];
    isEditable?: boolean;
    setRows?: (_: any) => void
}

const AppTable: React.FC<AppTableProps> = ({ columns, rows, setRows, isEditable = false }) => {
    const [editingRow, setEditingRow] = useState<number | null>(null);

    const handleEditClick = (index: number) => {
        setEditingRow(index);
    };

    const handleInputChange = (index: number, key: string, value: any) => {
        const updatedData = [...rows];
        updatedData[index][key] = value;
        setRows && setRows(updatedData);
    };

    const handleSaveClick = () => {
        setEditingRow(null); // Stop editing mode
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                                {column.label}
                            </TableCell>
                        ))}
                        {isEditable && <TableCell>Edit</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            {columns.map((column) => (
                                <TableCell key={column.id}>
                                    {editingRow === index ? (
                                        <TextField
                                            value={row[column.id]}
                                            onChange={(e) => handleInputChange(index, column.id, e.target.value)}
                                        />
                                    ) : (
                                        row[column.id]
                                    )}
                                </TableCell>
                            ))}
                            {isEditable && (
                                <TableCell>
                                    {editingRow === index ? (
                                        <IconButton onClick={handleSaveClick}>
                                            <DoneIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={() => handleEditClick(index)}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AppTable;

import { Box, Divider } from "@mui/material";
import { useState } from "react";
import AppDatePicker from "../../DatePicker";
import dayjs from "dayjs";
import TextBox from "../../TextBox";
import RatingComponent from "../../RatingComponent";
import AppButton from "../../AppButton";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../../redux/Reducer/userReducer";
import { UserService } from "../../../services/user.service";
import { toast } from "react-toastify";

const Examination = () => {
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );
  const dispatch = useDispatch();

  const [date, setDate] = useState<string>(userDetail.examDate);
  const [value, setValue] = useState(userDetail.exceptedGmatScore);
  const [loading, setLoading] = useState(false);
  const userService = new UserService();

  const handleSave = async () => {
    setLoading(true);
    dispatch(
      updateUserData({
        exceptedGmatScore: value,
        examDate: date,
      })
    );
    await userService.UpdateUser({
      ...userDetail,
      ...{
        exceptedGmatScore: value,
        examDate: date,
      },
    });
    toast.success("Data Updated");
    setLoading(false);
  };

  const toOddMultipleOfFive = (num: number): number => {
    let nearestMultipleOfFive = Math.round(num / 5) * 5;
    if (nearestMultipleOfFive % 2 === 0) {
      nearestMultipleOfFive += 5;
    }
    return nearestMultipleOfFive;
  };

  return (
    <Box className="examination-container">
      <p className="question">Proposed Exam date?</p>
      <p className="answer">
        Here is supposed to state the reason why we need this copy! For
        efficiency.
      </p>
      <p className="question">Date</p>
      <AppDatePicker
        className="exam-date"
        onChange={(e) =>
          setDate(e?.utc()?.format() || new Date().toUTCString())
        }
        value={dayjs(date)}
      />
      <Divider
        className="divider"
        sx={{ width: "100%", marginY: "20px" }}
      ></Divider>
      <p className="question">Target score</p>
      <p className="answer">
        Here is supposed to state the reason why we need this copy! For
        efficiency.
      </p>
      <Box className="input-container">
        <TextBox
          className="mark-text"
          value={value}
          onChange={(e) => setValue(Number(e.target.value))}
          onBlur={() => setValue((prev: number) => toOddMultipleOfFive(prev))}
        />
        <RatingComponent value={value} onChange={(e: number) => setValue(e)} />
      </Box>
      <Box className="btn-container">
        <AppButton
          label="Save Changes"
          onClick={handleSave}
          className="save-btn"
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default Examination;

import { BaseService } from "./base.service";


export class NotificationsService extends BaseService {
    public async GetNotifications() {
        try {
            const { data, status } = await this.httpClient.get("notification");

            if (status === 200) {
                return data;
            }
        }
        catch (error) {
        }
    }
}
import React, { useEffect } from "react";
import { motion } from "framer-motion";
import "./styles.scss";
import SequentialTypeWriter from "../Typewriter/sequential";
import { insertDecimal } from "../../utils/helper";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ErrorLogService } from "../../services/errorLog.service";

const AnalyseHistoryView: React.FC = () => {
  const ErrorLogApis = new ErrorLogService();
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.4 } },
  };

  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [jobs, setJobs] = React.useState([]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const Typewriter = SequentialTypeWriter();

  const getAllJobs = async () => {
    setJobs(await ErrorLogApis.GetAllJobs());
  };

  useEffect(() => {
    getAllJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {jobs?.map((job: any) => {
        return (
          <Accordion
            style={{ width: "100%" }}
            expanded={expanded === job.id}
            onChange={handleChange(job.id)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <p className="header">
                {" "}
                <span>
                  {new Date(job.createdat).toLocaleDateString()}{" "}
                  {new Date(job.createdat).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <motion.div
                className="analyze-view-container"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
              >
                <div className="view-cotainer">
                  <Typewriter index={0} className="header">
                    {job.message?.analysisReport?.title}
                  </Typewriter>
                  <div className="inner-content">
                    <Typewriter index={0} className="inner-header">
                      {job.message?.analysisReport?.subject}
                    </Typewriter>
                    <Typewriter index={0} className="exp-content">
                      {job.message?.analysisReport?.message}
                    </Typewriter>
                  </div>

                  {Object.keys(
                    job.message?.analysisReport?.studyRecommendation
                  ).map((title: string, i: number) => {
                    return (
                      <div className="study-details">
                        <Typewriter index={0} className="study-header">
                          {
                            job.message?.analysisReport?.studyRecommendation?.[
                              title
                            ]?.title
                          }
                        </Typewriter>
                        <Typewriter className="inner-header" index={0}>
                          {
                            job.message?.analysisReport?.studyRecommendation?.[
                              title
                            ]?.subject
                          }
                        </Typewriter>
                        <Typewriter className="exp-content" index={0}>
                          {
                            job.message?.analysisReport?.studyRecommendation?.[
                              title
                            ]?.message
                          }
                        </Typewriter>
                      </div>
                    );
                  })}

                  {Object.keys(job.message?.analysisReport?.performance).map(
                    (title: string, i: number) => {
                      return (
                        <div className="study-details">
                          <Typewriter index={0} className="study-header">
                            {
                              job.message?.analysisReport?.performance?.[title]
                                ?.label
                            }
                          </Typewriter>
                          <Typewriter index={0} className="base-value">
                            {insertDecimal(
                              job.message?.analysisReport?.performance?.[title]
                                ?.value
                            )}
                          </Typewriter>
                          <Typewriter index={0} className="exp-content">
                            {
                              job.message?.analysisReport?.performance?.[title]
                                ?.comment
                            }
                          </Typewriter>
                        </div>
                      );
                    }
                  )}
                </div>
              </motion.div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default AnalyseHistoryView;

import { BaseService } from "./base.service";


export class StudyPlanService extends BaseService {
    public async GetStudyPlans() {
        try {
            const { data, status } = await this.httpClient.get("studyPlans");

            if (status === 200) {
                return data;
            }
        }
        catch (error) {
        }
    }
}
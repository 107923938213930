// src/store/websocketSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WebSocketState {
  connected: boolean;
  messages: any[];
  socket: WebSocket | null;
}

const initialState: WebSocketState = {
  connected: false,
  messages: [],
  socket: null,
};

const websocketSlice = createSlice({
  name: "websocket",
  initialState,
  reducers: {
    setConnected(state, action: PayloadAction<boolean>) {
      state.connected = action.payload;
    },
    addMessage(state, action: PayloadAction<any>) {
      state.messages.push(JSON.parse(action.payload));
    },
    updateMessage(state, action: PayloadAction<any>) {
      state.messages = action.payload;
    },
    clearMessages(state) {
      state.messages = [];
    },
    socketFunction(state, action: PayloadAction<any>) {
      state.socket = action.payload;
    },
  },
});

export const {
  setConnected,
  addMessage,
  clearMessages,
  updateMessage,
  socketFunction,
} = websocketSlice.actions;
export default websocketSlice.reducer;

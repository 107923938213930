import {
  Calendar,
  CalendarProps,
  dayjsLocalizer,
  ToolbarProps,
  View,
} from "react-big-calendar";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { setDate, setNoOfSections } from "../../redux/Reducer/studyPlanReducer";
import { useLocation } from "react-router-dom";

const localizer = dayjsLocalizer(dayjs);
interface IProps extends Omit<CalendarProps, "localizer"> {
  height: number | string;
  width: number | string;
  eventList?: any[];
  className?: string;
}

const MyCalendar: React.FC<IProps> = ({
  height,
  width,
  eventList = [],
  className,
  ...props
}) => {
  const [colors, setColors] = useState<Record<string, any>>({});
  const [activeView, setActiveView] = useState<View>("month");
  const [currentDate, setCurrentDate] = useState(new Date());

  const search = useLocation().search;
  const query = new URLSearchParams(search).get("date");

  const incommingDate = useSelector((state: any) => state?.studyPlan?.date);

  useEffect(() => {
    if (query) {
      setActiveView("day");
      setCurrentDate(new Date(query));
      dispatch(setDate(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (incommingDate) {
      setActiveView("day");
      setCurrentDate(new Date(incommingDate));
      dispatch(setDate(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incommingDate]);

  const fixedColors = [
    { lightShade: "#FFF7ED", darkShade: "#EF884A" },
    { lightShade: "#EEF2FF", darkShade: "#6366F1" },
    { lightShade: "#F0FDFA", darkShade: "#134E4A" },
  ];

  const dispatch = useDispatch();

  const Card = (title: string, color: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          padding: "6px 10px",
          borderRadius: "5px",
          color: "#000",
          fontSize: "14px",
          fontWeight: "500",
          backgroundColor: color?.lightShade,
          position: "relative",
          filter: "brightness(1)",
        }}
      >
        <span
          style={{
            content: '""',
            position: "absolute",
            left: "0px",
            height: "60%",
            width: "2px",
            backgroundColor: color?.darkShade,
            marginLeft: "5px",
            borderRadius: "2px",
          }}
        />
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            flexGrow: 1,
            color: color?.darkShade,
          }}
        >
          {title}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (activeView === "agenda") {
      const noOfSections = eventList.length;
      dispatch(setNoOfSections(noOfSections));
    } else if (activeView === "day") {
      const startOfDay = new Date(currentDate);
      startOfDay.setHours(0, 0, 0, 0);

      const endOfDay = new Date(currentDate);
      endOfDay.setHours(23, 59, 59, 999);
      const noOfSections = [...eventList].filter(
        (x: any) =>
          new Date(x.start) >= startOfDay && new Date(x.end) <= endOfDay
      ).length;
      dispatch(setNoOfSections(noOfSections));
    } else if (activeView === "month") {
      const date = new Date(currentDate);

      // Calculate the start of the month
      const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      startOfMonth.setHours(0, 0, 0, 0);

      // Calculate the end of the month
      const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      endOfMonth.setHours(23, 59, 59, 999);
      const noOfSections = [...eventList].filter(
        (x: any) =>
          new Date(x.start) >= startOfMonth && new Date(x.end) <= endOfMonth
      ).length;
      dispatch(setNoOfSections(noOfSections));
    } else if (activeView === "week") {
      const date = new Date(currentDate);
      const dayOfWeek = date.getDay();

      // Calculate the start of the week (Sunday)
      const startOfWeek = new Date(date);
      startOfWeek.setDate(date.getDate() - dayOfWeek);
      startOfWeek.setHours(0, 0, 0, 0);

      // Calculate the end of the week (Saturday)
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      endOfWeek.setHours(23, 59, 59, 999);
      const noOfSections = [...eventList].filter(
        (x: any) =>
          new Date(x.start) >= startOfWeek && new Date(x.end) <= endOfWeek
      ).length;
      dispatch(setNoOfSections(noOfSections));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView, currentDate, eventList]);

  const CustomToolbar = (toolbar: ToolbarProps) => {
    const goToBack = () => {
      toolbar.onNavigate("PREV");
    };

    const goToNext = () => {
      toolbar.onNavigate("NEXT");
    };

    const goToToday = () => {
      toolbar.onNavigate("TODAY");
    };

    const changeview = (view: View) => {
      toolbar.onView(view);
      setActiveView(view);
    };

    return (
      <Box className="custom-toolbar">
        <Box className="btn-group">
          <button className="arrow" onClick={goToBack}>
            <NavigateBeforeIcon />
          </button>
          <button className="arrow" onClick={goToToday}>
            {activeView === "day"
              ? new Date(currentDate).toLocaleString("default", {
                  month: "long",
                  day: "2-digit",
                })
              : new Date(currentDate).toLocaleString("default", {
                  month: "long",
                })}
          </button>
          <button className="arrow" onClick={goToNext}>
            <NavigateNextIcon />
          </button>
        </Box>
        <Box className="btn-group">
          <button
            className={activeView === "month" ? "rbc-active" : ""}
            onClick={() => changeview("month")}
          >
            Month
          </button>
          <button
            className={activeView === "week" ? "rbc-active" : ""}
            onClick={() => changeview("week")}
          >
            Week
          </button>
          <button
            className={activeView === "day" ? "rbc-active" : ""}
            onClick={() => changeview("day")}
          >
            Day
          </button>
          <button
            className={activeView === "agenda" ? "rbc-active" : ""}
            onClick={() => changeview("agenda")}
          >
            Agenda
          </button>
        </Box>
      </Box>
    );
  };

  const updatedList = useMemo(() => {
    const temp = { ...colors };
    const data = eventList.map((x, index) => {
      if (typeof x.title === "string") {
        x.showable = x.title;
        if (!temp[x.title]) {
          temp[x.title] = fixedColors[Object.keys(temp).length % 3];
        }
        x.title = Card(x.title, temp[x.title]);
      }
      return x;
    });
    setColors(temp);
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventList]);

  return (
    <div className="app-calendar-container">
      <Calendar
        {...props}
        className={`app-calendar ${className || ""}`}
        views={{
          month: true,
          week: true,
          agenda: true,
          day: true,
        }}
        view={activeView}
        formats={{
          monthHeaderFormat: (date) =>
            date.toLocaleString("default", { month: "long" }),
          dayHeaderFormat: (date) =>
            date.toLocaleString("default", { month: "long" }),
          weekdayFormat: (date) =>
            date.toLocaleString("default", { month: "long" }),
        }}
        date={currentDate}
        components={{
          toolbar: CustomToolbar,
          day: {
            header: ({ date, localizer }) => localizer.format(date, "dddd"),
          },
        }}
        defaultView="month"
        popup={false}
        events={updatedList}
        onDrillDown={(date, view) => {
          setCurrentDate(date);
          setActiveView(view);
        }}
        messages={{ date: "Date", time: "Time", event: "Section" }}
        startAccessor="start"
        endAccessor="end"
        style={{ height, width }}
        localizer={localizer}
        tooltipAccessor={(ele: any) => ele?.showable}
        onNavigate={(date) => setCurrentDate(date)}
      />
    </div>
  );
};
export default MyCalendar;

import React, { useEffect, useRef } from "react";
import { ReactComponent as OpenBook } from "../../assets/light/OpenBook.svg";
import { Box, Divider, Tooltip } from "@mui/material";
import "./styles.scss";
import { Notification } from "../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import AppButton from "../AppButton";
import { useNavigate } from "react-router-dom";
import { setDate } from "../../redux/Reducer/studyPlanReducer";

const NotificationItem: React.FC<{
  notification: Notification;
  setNotificationPopper: any;
}> = ({ notification, setNotificationPopper }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="notification-item">
      <div className="notification-header">
        <OpenBook />
        <Box className="right-content">
          <h3>{notification.title}</h3>
          <span>
            {new Date(notification.timestamp).toLocaleDateString()}{" "}
            {new Date(notification.timestamp).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
          <div className="notification-content">
            <Box className="text-block">
              <Box className="title-content">
                <h4>{notification.subject}</h4>
                <div className="question-count">
                  <span className="count">{notification.questioncount}</span>{" "}
                  {notification.type === "summary" ? "Topics" : "Questions"}
                </div>
              </Box>
              {notification.subjectcategory && (
                <span className="subject-category">
                  {notification.subjectcategory}
                </span>
              )}
              <Tooltip title={notification.message}>
                <p>{notification.message}</p>
              </Tooltip>
            </Box>
            <AppButton
              label="Goto Study Plan"
              onClick={() => {
                dispatch(setDate(notification.timestamp));
                setNotificationPopper(false);
                navigate("/studyPlan");
              }}
              sx={{ width: "fit-content", margin: "10px" }}
            />
          </div>
        </Box>
      </div>
    </div>
  );
};

interface IProps {
  notifications: Notification[];
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
  setNotificationPopper: any;
}

const NotificationsComponent: React.FC<IProps> = ({
  notifications,
  setNotifications,
  setNotificationPopper,
}) => {
  const notificationRefs = useRef<(HTMLDivElement | null)[]>([]);
  const socket = useSelector((state: any) => state.websocket.socket);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const index = parseInt(entry.target.getAttribute("data-id") || "0", 10);
        if (entry.isIntersecting) {
          // Mark the notification as seen when it comes into view
          setNotifications((prevNotifications) =>
            prevNotifications.map((notif, i) => {
              if (i === index) {
                socket.send(
                  JSON.stringify({
                    type: "markNotificationRead",
                    data: { id: notif.id },
                  })
                );
                return { ...notif, isread: true };
              } else return notif;
            })
          );
        }
      });
    });

    notificationRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="notifications-popover">
      <div className="notifications-header">
        <h2 className="not-header">Notifications</h2>
        {/* <div className="clear-all">Clear All</div> */}
      </div>
      <Divider className="divider" sx={{ width: "100%" }} />
      <div className="notifications-list">
        {notifications.length ? (
          notifications.map((notification, index) => (
            <div
              key={notification.id}
              data-id={index} // Unique identifier for each notification
              ref={(el) => (notificationRefs.current[index] = el)}
            >
              <NotificationItem
                key={notification.id}
                notification={notification}
                setNotificationPopper={setNotificationPopper}
              />
            </div>
          ))
        ) : (
          <p className="no-content">No new notifications for you</p>
        )}
      </div>
    </Box>
  );
};

export default NotificationsComponent;

import React, { useState } from "react";
import LightStatic1 from "../../assets/light/static/static_1.svg";
import LightStatic2 from "../../assets/light/static/static_2.svg";
import DarkStatic1 from "../../assets/dark/static/static_1.svg";
import DarkStatic2 from "../../assets/dark/static/static_2.svg";
import Static3 from "../../assets/light/static/static_3.svg";
import { Box, Divider, Grid, Link } from "@mui/material";
import { useSelector } from "react-redux";
import { Controller, FormProvider, useForm } from "react-hook-form";
import AppButton from "../../components/AppButton";
import TextBox from "../../components/TextBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./styles.scss";

interface IProps {
  // page: string;
}

const ResetPassword: React.FC<IProps> = () => {
  const isDarkMode = useSelector((state: any) => state?.settings?.darkMode);
  const [loading, setLoading] = useState(false);
  const methods = useForm();
  const [visibility, setVisibility] = useState({
    pass: false,
    confirmPass: false,
  });

  const onSubmit = (data: any) => {
    setLoading(true)
    setLoading(false)
  }
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((data) => !loading && onSubmit(data))}
      >
        <Grid className="login-container">
          <Box position="absolute" bottom={0} right={0}>
            <img src={Static3} alt="" />
          </Box>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
              minHeight="70vh"
            >
              <Box className="reset-container">
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <h4 className="sign-in-header">Reset Password</h4>
                </Box>

                <Divider className="divider" sx={{ width: "100%", marginY: "16px" }}>
                </Divider>

                <Box className="text-field-container">
                  <Controller
                    name="password"
                    rules={{
                      required: "Password is required",
                      pattern: {
                        value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                        message: "passsword is invalid"
                      }
                    }}
                    render={({ field: { value, ...rest }, fieldState: { error } }) =>
                      <TextBox
                        preLabel="Password"
                        placeholder={"Create a password"}
                        // hint={"Must be at least 8 characters."}
                        errorMessage={error?.message} type={visibility.pass ? "text" : "password"}
                        icon={{
                          position: "end",
                          icon: !visibility.pass ? <VisibilityIcon className="cursor-point" /> : <VisibilityOffIcon className="cursor-point" />,
                          onClick: () => {
                            setVisibility((prev) => ({ ...prev, pass: !prev.pass }));
                          },
                        }}
                        value={value || ""}
                        {...rest}
                      />
                    } />
                  <Controller
                    name="confirmPassword"
                    rules={{
                      required: "Confirm Password is required",
                      validate: (value) => {
                        return value !== methods.getValues("password") ? "Passwords doen't match" : true
                      }
                    }}
                    render={({ field: { value, ...rest }, fieldState: { error } }) =>
                      <TextBox
                        preLabel="Confirm Password"
                        placeholder={"Confirm a password"}
                        // hint={"Must be at least 8 characters."}
                        errorMessage={error?.message} type={visibility.confirmPass ? "text" : "password"}
                        icon={{
                          position: "end",
                          icon: !visibility.confirmPass ? <VisibilityIcon className="cursor-point" /> : <VisibilityOffIcon className="cursor-point" />,
                          onClick: () => {
                            setVisibility((prev) => ({ ...prev, confirmPass: !prev.confirmPass }));
                          },
                        }}
                        value={value || ""}
                        {...rest}
                      />
                    } />
                  <Box className="forgot-cont">
                    <Link className="forgot-pass" href="sign-in" underline="none">
                      Go to Sign in
                    </Link>
                  </Box>
                </Box>

                <AppButton loading={loading} label="Reset Password" type="submit" className="login-btn" />

              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: "flex" }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="baseline"
              height="fit-content"
              gap="20px"
            >
              <img src={isDarkMode ? DarkStatic1 : LightStatic1} alt="" />
              <img src={isDarkMode ? DarkStatic2 : LightStatic2} alt="" />
            </Box>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ResetPassword;

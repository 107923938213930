import React, { useEffect, useMemo } from "react";
import "./styles.scss";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import LoginForm from "../loginPage";
import OnBoardingPage from "../onBoardingPage";
import SideLayout from "../../layout/sideMenu";
import { useDispatch, useSelector } from "react-redux";
import Analyser from "../analyser";
import HeaderLayout from "../../layout/header";
import Dashboard from "../dashboard";
import StudyPlan from "../studyPlan";
import Profile from "../profile";
import { setAuthToken, updateUserData } from "../../redux/Reducer/userReducer";
import { UserService } from "../../services/user.service";
import ResetPassword from "../resetPassword";
import axios from "axios";
import { loader } from "../../redux/Reducer/settingsReducer";
import { NotificationsService } from "../../services/notifications.service";
import { updateMessage } from "../../redux/Reducer/webSocketReducer";

const AppRoutes: React.FC = () => {
  const userService = new UserService();
  const notificationService = new NotificationsService();
  const dispatch = useDispatch();
  const { isAuthenticated, loginSource } = useSelector(
    (state: any) => state?.userDetails
  );
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );

  const getUserDetails = async () => {
    const data = await userService.GetUser();
    if (!!data?.data) dispatch(updateUserData(data.data));
  };

  const getNotifications = async () => {
    const data = await notificationService.GetNotifications();
    if (!!data?.data) dispatch(updateMessage(data.data));
  };

  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    const fetchAuthToken = async (code: string) => {
      const tokenUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`;

      const data = {
        grant_type: "authorization_code",
        client_id: encodeURIComponent(
          process.env.REACT_APP_AUTH0_CLIENT_ID as string
        ),
        code: code,
        redirect_uri: window.location.origin + "/dashboard", // Update with your redirect URI
      };

      try {
        const response = await axios.post(tokenUrl, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        dispatch(
          setAuthToken({
            token: response?.data?.id_token,
            source: "login",
          })
        );
        await getUserDetails();
        dispatch(loader({ isLoading: false }));
      } catch (err: any) {
        console.error(
          "Error fetching Auth0 token:",
          err.response?.data || err.message
        );
      }
    };

    // Extract the authorization code from the memoized queryParams
    const code = queryParams.get("code");

    if (code) {
      dispatch(loader({ isLoading: true }));
      fetchAuthToken(code);
    } else {
      console.log("Authorization code not found.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    if (isAuthenticated) {
      getUserDetails();
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <div className="landing-page">
      <Routes>
        {/* Authenticated Routes */}
        <Route
          path="/"
          element={isAuthenticated ? <Outlet /> : <Navigate to={"/sign-in"} />}
        >
          <Route
            path="/"
            element={
              userDetail.onBoarding === "completed" ? (
                <Navigate to={"/dashboard"} />
              ) : (
                <Navigate to={"/onboarding"} />
              )
            }
          />
          <Route path="/" element={<SideLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/analyser" element={<Analyser />} />
            <Route path="/studyPlan" element={<StudyPlan />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/reports" element={<></>} />
          </Route>
          <Route path="/" element={<HeaderLayout auth={false} />}>
            <Route path="/onboarding" element={<OnBoardingPage />} />
          </Route>
          <Route path="/*" element={<Navigate to={"/dashboard"} />} />
        </Route>
        {/* Unauthenticated Routes */}
        <Route
          path="/"
          element={
            !isAuthenticated ? (
              <Outlet />
            ) : loginSource === "login" ? (
              <Navigate to={"/dashboard"} />
            ) : (
              <Navigate to={"/onboarding"} />
            )
          }
        >
          <Route path="/" element={<Navigate to={"/sign-in"} />} />
          <Route path="/" element={<HeaderLayout auth={true} />}>
            <Route path="/sign-in" element={<LoginForm page={"signIn"} />} />
            <Route path="/sign-up" element={<LoginForm page={"signUp"} />} />
            <Route
              path="/forgot-password"
              element={<LoginForm page={"forgotPassword"} />}
            />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route path="/*" element={<Navigate to={"/"} />} />
        </Route>
      </Routes>
    </div>
  );
};

export default AppRoutes;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    noOfSections: 0
};

const StudyPlanReducer = createSlice({
    name: 'studyPlanReducer',
    initialState,
    reducers: {
        setNoOfSections(state: any, payload) {
            state.noOfSections = payload?.payload
        },
        setDate(state: any, payload) {
            state.date = payload?.payload
        }
    }
});

export const { setNoOfSections, setDate } = StudyPlanReducer.actions;
export default StudyPlanReducer.reducer;

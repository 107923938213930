import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { ReactComponent as BackIcon } from "../../assets/light/Arrow.svg";
import "./styles.scss";
import { CircularProgress } from "@mui/material";

interface IProps extends ButtonProps {
  className?: string;
  customVariant?:
    | "cancel"
    | "back"
    | "analyse"
    | "custom-outlined"
    | "disabled";
  label: string;
  loading?: boolean;
}

const AppButton: React.FC<IProps> = ({
  className,
  label,
  customVariant,
  loading,
  variant = "contained",
  ...rest
}) => {
  return (
    <Button
      variant={variant}
      className={`${className || ""} ${
        customVariant ? `button-${customVariant}` : ""
      } common-button`}
      startIcon={customVariant === "back" ? <BackIcon /> : <></>}
      {...rest}
    >
      {loading ? <CircularProgress color="inherit" size="25px" /> : label}
    </Button>
  );
};

export default AppButton;

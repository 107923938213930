// src/hooks/useSocket.ts
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessage,
  setConnected,
  socketFunction,
} from "../redux/Reducer/webSocketReducer";

const useSocket = () => {
  const dispatch = useDispatch();
  // const [ws, setWs] = useState<WebSocket | null>(null);

  const token = useSelector((state: any) => state.userDetails.authToken);

  useEffect(() => {
    // Connect to the WebSocket server
    const socket = new WebSocket(process.env.REACT_APP_DREAMFYER_WS_URL || "");

    socket.onopen = () => {
      dispatch(setConnected(true));
      dispatch(socketFunction(socket));
      socket.send(JSON.stringify({ type: "auth", token: `Bearer ${token}` }));
      // setWs(socket);
    };

    socket.onmessage = (event) => {
      dispatch(addMessage(event.data));
      // setMessages((prevMessages) => [...prevMessages, event.data]);
    };

    socket.onclose = () => {
      dispatch(setConnected(false));
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      socket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSocket;

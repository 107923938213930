import { Box, Link } from "@mui/material";
import React from "react";
import "./styles.scss";

const FooterBar: React.FC = () => {
  return (
    <Box className="bottom-bar-container">
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Link href="https://www.pocketbud.io/terms" underline="none">
          Terms
        </Link>
        <Link href="https://www.pocketbud.io/privacypolicy" underline="none">
          Privacy
        </Link>
      </div>
    </Box>
  );
};

export default FooterBar;

import { useEffect, useState } from "react";
import AppToggle from "../../Toggle";
import { Box, Divider } from "@mui/material";
import { subscribe } from "../../../utils/helper";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
import "./styles.scss";
import AppButton from "../../AppButton";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../../redux/Reducer/userReducer";
import { UserService } from "../../../services/user.service";

const Notification = () => {
  const userDetail = useSelector(
    (state: any) => state?.userDetails?.userDetails
  );

  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<string>(
    userDetail?.notificationSettings?.startTime || ""
  );
  const [endTime, setEndTime] = useState<string>(
    userDetail?.notificationSettings?.endTime || ""
  );
  const [loading, setLoading] = useState(false);
  const [registration, setRegistration] =
    useState<ServiceWorkerRegistration | null>(null);
  const userService = new UserService();

  const dispatch = useDispatch();

  useEffect(() => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      navigator.serviceWorker
        .register("/sw.js")
        .then((reg) => {
          setRegistration(reg);
        })
        .catch((err) =>
          console.error("Service Worker registration failed:", err)
        );
    }
  }, []);
  useEffect(() => {
    if (registration)
      registration.pushManager.getSubscription().then((sub) => {
        if (sub) {
          setIsSubscribed(true);
        }
      });
  }, [registration]);

  const subscribeUser = async (): Promise<void> => {
    setLoading(true);
    try {
      if (registration) {
        const sub = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            "BJRLVUkr-9HTM-md5GHl4b2JJ4zQaY3KaYQvL2g6unTgBcWYfisWCwjMR3VV98podzqlphfnySoUl3Ct2PbHFnA",
        });
        setIsSubscribed(true);
        // Send the subscription to your server
        await subscribe(sub);
      }
    } catch (err) {
      console.error("Failed to subscribe the user: ", err);
    }
    setLoading(false);
  };

  const unsubscribeUser = async (): Promise<void> => {
    try {
      if (registration) {
        const sub = await registration.pushManager.getSubscription();
        if (sub) {
          await sub.unsubscribe();
        }
        setIsSubscribed(false);
        // Remove the subscription from your server
        await removeSubscriptionFromServer(sub);
      }
    } catch (err) {
      console.error("Error unsubscribing", err);
    }
  };

  const removeSubscriptionFromServer = async (
    sub: PushSubscription | null
  ): Promise<void> => {
    // Implement the logic to remove the subscription from your server
  };

  const handleSave = async () => {
    setLoading(true);
    dispatch(
      updateUserData({
        notificationSettings: { startTime, endTime },
      })
    );
    await userService.UpdateUser({
      ...userDetail,
      ...{ notificationSettings: { startTime, endTime } },
    });
    toast.success("Data Updated");
    setLoading(false);
  };

  return (
    <Box className="notification-container">
      <Box className="top-box">
        <Box className="question-container">
          <Box className="text-container">
            <p className="question">Notification Settings</p>
            <p className="answer">
              Here is supposed to state the reason why we need this copy! For
              efficiency.
            </p>
          </Box>
          <AppToggle
            loading={loading}
            checked={isSubscribed}
            onChange={(e) => {
              if (e.target?.checked) {
                subscribeUser();
              } else unsubscribeUser();
            }}
          />
        </Box>
        <Divider
          className="divider"
          sx={{ width: "100%", marginY: "20px" }}
        ></Divider>
        <Box className="question-container">
          <Box className="text-container">
            <p className="question">Notification Settings</p>
            <div style={{ marginTop: "20px", display: "flex", gap: "20px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start Time"
                  views={["hours", "minutes"]}
                  value={dayjs(startTime)}
                  onChange={(newValue) =>
                    setStartTime(dayjs(newValue).toISOString())
                  }
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="End Time"
                  views={["hours", "minutes"]}
                  value={dayjs(endTime)}
                  onChange={(newValue) =>
                    setEndTime(dayjs(newValue).toISOString())
                  }
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </div>
          </Box>
        </Box>
      </Box>
      <Box className="btn-container">
        <AppButton
          label="Save Changes"
          onClick={handleSave}
          className="save-btn"
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default Notification;

/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import * as XLSX from "xlsx";
import "./styles.scss";
import FileUpload from "../../components/FileUpload";
import AppButton from "../../components/AppButton";
import { ReactComponent as TrashCan } from "../../assets/light/TrashCan.svg";
import { ReactComponent as FileLogo } from "../../assets/light/Page.svg";
import { ReactComponent as HistoryIcon } from "../../assets/light/historyIcon.svg";
import AnalyseView from "../../components/analyse/view";
import { mockData } from "../../assets/data/mockData";
import { Loader } from "../../components/Loader";
import { ErrorLogService } from "../../services/errorLog.service";
import { sendErrorDataToBackend } from "../../utils/helper";
import {
  eGmatHeaders,
  gmatWhizHeaders,
  TemplateId,
  testPrepHeaders,
} from "../../utils/constants";
import { errorMapper } from "../../utils/mapper";
import { useDispatch } from "react-redux";
import { resetChart } from "../../redux/Reducer/chartDataReducer";
import Tesseract, { PSM } from "tesseract.js";
import { Jimp } from "jimp";
import AppTable from "../../components/Table";
import AnalyseHistoryView from "../../components/analyseHistory";

interface ISelectedFileDetails {
  file?: File;
  fileType?: string;
  fileTemplate?: string;
  fileData?: any;
}

const Analyser = () => {
  const matches = useMediaQuery("(min-width:800px)");
  const ErrorLogApis = new ErrorLogService();
  const dispatch = useDispatch();
  const [files, setFiles] = useState<File[]>([]);
  const [analysed, setAnalysed] = useState(false);
  const [history, setHistory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [percent, setPercent] = useState(0);
  const intervalref = useRef<null | NodeJS.Timer>(null);
  const workerRef = useRef<null | Tesseract.Worker>(null);
  const [parsedFiles, setParsedFiles] = useState<ISelectedFileDetails[]>([]);
  const [analyzedData, setAnalyzedData] = useState([]);
  const [summary, setSummary] = useState(mockData);

  const tableHeader = useMemo(() => {
    switch (parsedFiles?.[0]?.fileTemplate) {
      case "gmatwhiz":
        return gmatWhizHeaders;
      case "e-gmat":
        return eGmatHeaders;
      case "targettestprep":
        return testPrepHeaders;
      default:
        return [];
    }
  }, [analyzedData]);

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleOnSave = async () => {
    let jobId = "";
    try {
      jobId = await sendErrorDataToBackend(analyzedData, ErrorLogApis);
    } catch (error) {
      console.error(error);
    }
    return jobId;
  };

  const identifySource = (inputText: string) => {
    const patterns = {
      "e-gmat": /^\d+\s+[A-Z]{2,3}\s+[\w\s]+\s+[E|EM]\s+\[x?\]\s+\d{2}:\d{2}/m,
      targettestprep: /[@|©]\s*Question\d+\s+PROBLEM SOLVING/,
      gmatwhiz: /^\d+\s+\d{3}-\d{3}\s+Level\s+\d{1,3}:\d{2}\s+MM:SS/m,
    };

    for (let [source, pattern] of Object.entries(patterns)) {
      if (pattern.test(inputText)) {
        return source;
      }
    }

    return "Unknown source";
  };

  const convertImageToText = async (
    imageFile: File | string,
    added = false
  ) => {
    if (!workerRef.current)
      workerRef.current = await Tesseract.createWorker("eng");
    const worker = workerRef.current;
    await worker.setParameters({
      tessedit_pageseg_mode: PSM.SINGLE_BLOCK,
      ...(added
        ? {
            tessedit_char_whitelist:
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789:()+-=/%@© ",
          }
        : {}),
    });
    const {
      data: { text },
    } = await worker.recognize(imageFile);
    return { data: text, source: identifySource(text), image: imageFile };
  };

  const fileExtractor = async (file: File) => {
    if (["image", "png", "img"].some((x) => file.type.includes(x))) {
      const { data, source } = await convertImageToText(file);
      return { type: "image", data: [data], source };
    } else {
      return { type: "excel", data: await processExcel(file), source: "GMAT" };
    }
  };

  const preProcessImage = async (
    imageFile: File,
    template: TemplateId
  ): Promise<any> => {
    const imageBuffer = await imageFile.arrayBuffer();
    const image = await Jimp.read(imageBuffer);
    let processedImage;
    switch (template) {
      case TemplateId.GMATWHIZ:
        processedImage = await image
          .greyscale()
          .invert()
          .contrast(0.1)
          .brightness(0.2)
          .threshold({ max: 255 })
          .getBase64("image/png");
        break;
      case TemplateId.TESTPREP:
        processedImage = await image
          .contrast(0.1)
          .greyscale()
          .threshold({ max: 255 })
          .getBase64("image/png");
        break;

      default:
        break;
    }
    return processedImage || imageFile;
  };

  const processExcel = (file: File): Promise<any> => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const json: any = XLSX.utils.sheet_to_json(firstSheet);
        resolve(json);
      };

      reader.onerror = (err) => {
        console.error(err);
        resolve("");
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const onAnalyze = async (acceptedFiles: File[]) => {
    setFileLoading(true);
    const parsedData: ISelectedFileDetails[] = await Promise.all(
      acceptedFiles.map(async (each: File) => {
        const { data, source, type } = await fileExtractor(each);
        return {
          file: each,
          fileType: type,
          fileTemplate: source,
          fileData: data,
        };
      })
    );
    setParsedFiles([...structuredClone(parsedData)]);
    const filteredData: ISelectedFileDetails[] = parsedData.filter(
      (each) =>
        each.fileType === parsedData?.[0]?.fileType &&
        each.fileTemplate === parsedData?.[0]?.fileTemplate
    );
    const newParsed = (
      await Promise.all(
        filteredData.map(async (x) => {
          if (x.fileType === "excel") {
            return errorMapper(x.fileData, TemplateId.GMATClub);
          } else if (x.fileTemplate === "e-gmat") {
            return errorMapper(x.fileData as string[], TemplateId.EGMAT);
          } else if (x.fileTemplate === "targettestprep") {
            const processedFile: any = await preProcessImage(
              x.file as File,
              TemplateId.TESTPREP
            );
            const fileData = await convertImageToText(processedFile, true);
            return errorMapper([fileData.data], TemplateId.TESTPREP);
          } else if (x.fileTemplate === "gmatwhiz") {
            const processedFile: any = await preProcessImage(
              x.file as File,
              TemplateId.GMATWHIZ
            );
            const fileData = await convertImageToText(processedFile);
            return errorMapper([fileData.data], TemplateId.GMATWHIZ);
          }
          return [];
        })
      )
    ).reduce((all, each) => [...all, ...each], []);
    setAnalyzedData(newParsed);
    setFileLoading(false);
    return newParsed;
  };

  const handleAnalyze = async () => {
    setLoading(true);
    const jobId = await handleOnSave();
    intervalref.current = setInterval(async () => {
      if (!!jobId) {
        const res = await ErrorLogApis.GetSummary(jobId);
        if (res.data.status === "Completed") {
          setSummary(res.data.message);
          setPercent(100);
        } else if (percent < 90) setPercent((prev) => prev + 5);
      } else if (percent < 90) setPercent((prev) => prev + 5);
    }, 2000);
  };

  useEffect(() => {
    if (percent === 100) {
      setTimeout(() => {
        setLoading(false);
        setAnalysed(true);
        clearInterval(intervalref.current as NodeJS.Timer);
        intervalref.current = null;
      }, 1000);
      dispatch(resetChart());
    }
  }, [percent, dispatch]);

  useEffect(() => {
    setHistory(false);
    return () => {
      workerRef?.current?.terminate();
    };
  }, []);

  useEffect(() => {
    if (files.length) onAnalyze(files);
    else {
      setParsedFiles([]);
      setAnalyzedData([]);
    }
  }, [files]);

  return (
    <Box className={`analyser-container ${analysed ? "view" : ""}`}>
      {!analysed && !history && (
        <div
          style={{
            height: "100%",
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <HistoryIcon
              style={{ cursor: "pointer" }}
              onClick={() => setHistory(true)}
            />
            <div></div>
            <div></div>
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box className="content-container">
              <Box className="top-container">
                <p className="header">Analyse Error Log</p>
                <p className="hint">
                  Get instant analysis to help you move forward in your study.
                </p>
                {files.length > 0 ? (
                  <div
                    className="file-list"
                    style={{ width: matches ? "30rem" : "100%" }}
                  >
                    {files.map((file, index) => (
                      <div className="file-item" key={index}>
                        <FileLogo />
                        <div className="file-info">
                          <p>{file.name}</p>
                          {/* <p>{file.type}</p> */}
                        </div>
                        <div className="file-actions">
                          <TrashCan onClick={() => handleRemoveFile(index)} />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <FileUpload
                    files={files}
                    onFilesSelected={(e: any) => setFiles(e)}
                    width={matches ? "30rem" : "100%"}
                    height={"100%"}
                    innerFiles={false}
                    accept=".csv,.xlsx,.jpg,.png"
                    multiple={true}
                  />
                )}
              </Box>
              <Box className="down-container">
                <p className="hint">
                  {parsedFiles.length
                    ? `${parsedFiles[0]?.fileTemplate} template is chosen`
                    : ""}
                </p>
                <AppButton
                  onClick={() => handleAnalyze()}
                  // onClick={() => test()}
                  label="Analyze"
                  className={!files.length ? "analyze-button" : "active-button"}
                  disabled={!files.length}
                />
              </Box>
              {!!analyzedData?.length &&
                parsedFiles[0]?.fileType === "image" && (
                  <AppTable
                    columns={tableHeader}
                    rows={analyzedData || []}
                    isEditable={true}
                    setRows={setAnalyzedData}
                  />
                )}
              <Loader open={fileLoading} />
            </Box>
          </div>
        </div>
      )}
      {analysed && <AnalyseView data={{ ...summary, file: files[0] }} />}
      {history && <AnalyseHistoryView data={{ ...summary, file: files[0] }} />}
      <Loader open={loading} progress={true} progressPercent={percent} />
    </Box>
  );
};

export default Analyser;
